import type { DepartmentOptionsObject } from "../../translation-keys/department-option-keys.js";

const FormDepartmentOptions: DepartmentOptionsObject = {
  "Accounting/Finance": "Accounting/Finance",
  "Blood Bank": "Blood Bank",
  Clinical: "Clinical",
  "Customer Service": "Customer Service",
  "Document Control": "Document Control",
  EHS: "EHS",
  Engineering: "Engineering",
  "Executive (C-level or VP)": "Executive (C-level or VP)",
  "Human Resources": "Human Resources",
  "IT / Information Systems": "IT / Information Systems",
  Laboratory: "Laboratory",
  Manufacturing: "Manufacturing",
  Operations: "Operations",
  Other: "Other",
  Packaging: "Packaging",
  "Product Development": "Product Development",
  Purchasing: "Purchasing",
  Quality: "Quality",
  "R&D": "R&D",
  "R&D QA": "R&D QA",
  Regulatory: "Regulatory",
  "Sales / Marketing": "Sales / Marketing",
  Training: "Training",
  Validation: "Validation",
};

export default FormDepartmentOptions;